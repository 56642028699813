import React, { FunctionComponent, useEffect } from "react";
import { CSSTransition, Transition } from "react-transition-group";
import { isCallSignatureDeclaration } from "typescript";

type modalProps = {
    header: JSX.Element;
    body: JSX.Element;
    footer: JSX.Element;
    closeModalHook: () => void;
}


const Modal: FunctionComponent<modalProps> = ({ header, body, footer, closeModalHook }: modalProps) => {

    useEffect(()=>{
        //set body as no scroll
    
        document.querySelector('body')?.classList.add('modal-open');
        return ()=>{
          //remove class from body
          window.setTimeout(()=>{
            let nodes = document.querySelectorAll('.modal-not-bs');
            if(nodes.length === 0){
              document.querySelector('body')?.classList.remove('modal-open');
            }
          },100);
        };
      },[]);

    const closeModal = () => {
        if(closeModalHook) closeModalHook();
        else {
            console.log(`closeModalHook not defined for this component.`);
        }
    }

    const closeModalSelfOnly = (event: React.MouseEvent) => {
        console.debug("closeModalSelfOnly", event.target, event.currentTarget);
        if (event.target === event.currentTarget) {
            closeModal();
        }
    }


    return (
        <div className="modal-mask">
            <div className="modal-wrapper" onClick={closeModalSelfOnly}>
                <div className="modal-container">

                    <div className="modal-header">
                        {header}
                    </div>

                    <div className="modal-body">
                        {body}
                    </div>

                    <div className="modal-footer">
                        {footer}
                        <button className="btn btn-secondary" type="button" onClick={closeModal}>
                            Close
                  </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;