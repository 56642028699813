import { useEffect, useState } from 'react';
import { IState } from '../../../redux/redux';
import Icon, { IconType } from '../../Icon/Icon';
import { store } from '../../../redux/oldStore';
import { ServerResponse } from '../../../utils/Server';
import { toast } from 'react-toastify';
import { ICircleGeneratorGradeInfo } from './JudgingCirlcesTypes';
import JudgingCircleGenatorGrade from './JudgingCircleGenatorGrade';

interface IProps {
  state: IState;
  roundId: number;
  onRun: () => void;
}

interface iform {
  roundId?: number;
  prefix?: string;
  assignJudges?: boolean;
  assignProjects?: boolean;
  circles: ICircleGeneratorGradeInfo[];
}

const JudgingCircleGenator = (props: IProps) => {
  const [form, setForm] = useState<iform>({ circles: [] });

  useEffect(() => {
    let f = { ...form };
    f.circles.push({ active: true, maxGrade: 0, numberOfJudges: 3, numberOfProjects: 8 });
    f.circles.push({ active: true, maxGrade: 1, numberOfJudges: 3, numberOfProjects: 8 });
    f.circles.push({ active: true, maxGrade: 2, numberOfJudges: 3, numberOfProjects: 8 });
    f.circles.push({ active: true, maxGrade: 3, numberOfJudges: 3, numberOfProjects: 8 });
    f.circles.push({ active: true, maxGrade: 4, numberOfJudges: 3, numberOfProjects: 8 });
    f.circles.push({ active: true, maxGrade: 5, numberOfJudges: 3, numberOfProjects: 8 });
    f.circles.push({ active: true, maxGrade: 6, numberOfJudges: 3, numberOfProjects: 8 });
    f.circles.push({ active: true, maxGrade: 7, numberOfJudges: 3, numberOfProjects: 8 });
    f.circles.push({ active: true, maxGrade: 8, numberOfJudges: 3, numberOfProjects: 8 });
    f.circles.push({ active: true, maxGrade: 9, numberOfJudges: 3, numberOfProjects: 8 });
    f.circles.push({ active: true, maxGrade: 10, numberOfJudges: 3, numberOfProjects: 8 });
    f.circles.push({ active: true, maxGrade: 11, numberOfJudges: 3, numberOfProjects: 8 });
    f.circles.push({ active: true, maxGrade: 12, numberOfJudges: 3, numberOfProjects: 8 });
    setForm(f);
  }, [props.roundId]);

  const onGenerate = () => {
    toast.dismiss();
    if (!window.confirm(`${props.state.User?.firstName} are you sure you want to generate circles? This will clear any existing circles and assignments for this round?`)) {
      return;
    }

    form.roundId = props.roundId;

    store.server.postApi<ServerResponse<boolean>>(`../judgingCircles/CirclesGenerate`, form).then((x) => {
      if (x.Success) {
        toast.info('Circles generated!');
        props.onRun();
      } else {
        toast.error(x.Message);
      }
    });
  };

  return (
    <>
      <div className="form-horizontal">
        <div className="form-group">
          <div className="col-sm-4"></div>
          <div className="col-sm-8"></div>
        </div>
        <div className="form-group">
          <label className="col-sm-4 control-label" htmlFor="xxxx-prefix">
            Circle Name Prefix
          </label>
          <div className="col-sm-8">
            <input
              type={'text'}
              id={'xxxx-prefix'}
              className="form-control"
              maxLength={10}
              value={form.prefix ?? ''}
              onChange={(x) => {
                setForm({ ...form, prefix: x.target.value });
              }}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-4"></div>
          <div className="col-sm-8">
            <input
              type={'checkbox'}
              id={'xxxx-assignJudges'}
              className="form-controlx"
              checked={form.assignJudges ?? false}
              onChange={(x) => {
                setForm({ ...form, assignJudges: x.target.checked });
              }}
            />
            <label className="control-label" htmlFor="xxxx-assignJudges">
              Auto assign judges to circles
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-4"></div>
          <div className="col-sm-8">
            <input
              type={'checkbox'}
              id={'xxxx-assignProjects'}
              className="form-controlx"
              checked={form.assignProjects ?? false}
              onChange={(x) => {
                setForm({ ...form, assignProjects: x.target.checked });
              }}
            />
            <label className="control-label" htmlFor="xxxx-assignProjects">
              Auto assign entries to circles
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-4"></div>
          <div className="col-sm-8">
            <table>
              <tr>
                <th>Grade</th>
                <th>Build</th>
                <th>Prefix</th>
                <th>
                  Judges <Icon type={IconType.circle} />
                </th>
                <th>
                  Projects <Icon type={IconType.circle} />
                </th>
              </tr>
              {form.circles.map((x, i) => {
                return (
                  <JudgingCircleGenatorGrade
                    key={`jcgg-jcg-${i}`}
                    gradeInfo={x}
                    onSet={(circle: ICircleGeneratorGradeInfo) => {
                      let circles = form.circles;
                      circles[i] = circle;
                      setForm({ ...form, circles: circles });
                    }}
                    state={props.state}
                  />
                );
              })}
            </table>
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-4"></div>
          <div className="col-sm-8">
            <button type={'button'} className="btn btn-secondary" onClick={onGenerate}>
              <Icon type={IconType.build} /> Generate Circles
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default JudgingCircleGenator;
