import React from "react";
import SchoolPicker from "./SchoolPicker";

interface IProps {}

const TestGround = () =>{

    return <>
        <h2>Testing Ground</h2>
        <hr/>

<div className="form-horizontal">
    <div className="form-group">
        <label className="control-label col-sm-4">School</label>
        <div className="col-sm-8">
            <SchoolPicker
            value={6736}
            onChange={(x=>{
                console.log('Value Changed:', x);
            })}
            />
        </div>
    </div>
</div>

    </>
};

export default TestGround;