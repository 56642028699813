import React, { useState, useEffect } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { toast } from 'react-toastify';
import StoreX, { store } from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';
import Icon, { IconType } from '../Icon/Icon';
import Tools from '../JudgingDashboard/Tools';

interface IInvoicemanagement {}

interface IInvoiceData {
  invoiceRecords: IInvoiceRecord[];
  shouldShowAffiliates: boolean;
  problemInvoices: IInvoiceProblemGroup[];
  Coupons: ICouponInfo[];
  Products: IProductInfo[];
}

interface ICouponInfo {
  Id: number;
  Code: string;
  Amount: number;
  CoupontType: 'percent' | 'dollar' | undefined;
}

interface IProductInfo {
  Id: number;
  Name: string;
  Cost: number;
  AdvancedType: string;
}

interface IInvoiceProblemGroup {
  ProjectId: string;
  Title: string;
  HasNonRegFees: boolean;
  NumberOfOrders: number;
  Orders: IInvoiceProblemOrder[];
  Paid: boolean;
}

interface IInvoiceProblemOrder {
  InvoiceUrl: string;
  CreatedAt: string;
  HasNonRegFees: boolean;
  OrderId: number;
  OrderPublicId: string;
  Paid: boolean;
  PaidAt?: string;
  Total: number;
}

interface IInvoiceRecord {
  Id: string;
  accountHolder: string;
  accountHolderEmail: string;
  affiliate: string;
  affiliateId: number;
  invoiceUri: string;
  orderDate: string;
  orderId: number;
  orderStatus: string;
  paid: boolean;
  searchString: string;
  totalAmountPaid: string;
  transactionNumber: string;
  CouponIds: number[] | undefined;
  CouponCodes: string[] | undefined;
}

export const InvoiceMangement = (props: IInvoicemanagement) => {
  const [months, setMonths] = useState<number>(6);
  const [data, setData] = useState<IInvoiceData>();
  const [filteredRecords, setFilteredRecords] = useState<IInvoiceRecord[]>([]);
  const [filteredProblems, setFilteredProblems] = useState<IInvoiceProblemGroup[]>([]);
  const [form, setForm] = useState<any>({});
  const [checked, setChecked] = useState<Record<number, boolean>>({});

  useEffect(() => {
    LoadData(months);
  }, []);

  useEffect(() => {
    let search: string[] = form.search?.toLowerCase().split(' ') ?? [];
    if (search && search.length > 0 && search[0]) {
      let records = data?.invoiceRecords.filter((x) => {
        return search.find((s) => x.searchString.indexOf(s) > -1 || (x.CouponCodes && x.CouponCodes.find((c) => c.toLowerCase() == s)));
      });
      setFilteredRecords(records ?? []);
    } else {
      setFilteredRecords(data?.invoiceRecords ?? []);
    }
  }, [data?.invoiceRecords, form.search]);

  useEffect(() => {
    let search: string[] = form.searchsearchProblem?.toLowerCase().split(' ') ?? [];
    if (search && search.length > 0 && search[0]) {
      let records = data?.problemInvoices.filter((x) => {
        return true;
      });
      setFilteredProblems(records ?? []);
    } else {
      setFilteredProblems(data?.problemInvoices ?? []);
    }
  }, [data?.problemInvoices, form.searchProblem]);

  const LoadData = (monthsToShow: number) => {
    store.server.postApi<ServerResponse<IInvoiceData>>(`../Purchase/GetInvoices/${monthsToShow}`, {}).then((x) => {
      if (x.Success) {
        setData(x.Value);
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const RemoveInvoice = (id: string) => {
    if (window.confirm(`Are you sure you want to remove this invoice?`)) {
      store.server.postApi<ServerResponse<IInvoiceData>>(`../Purchase/RemoveInvoices`, { ids: [id] }).then((x) => {
        if (x.Success) {
          LoadData(months);
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
    }
  };

  const MakeInvoiceFromSelected = () => {
    let orders: number[] = [];
    filteredRecords?.forEach((x) => {
      if (checked[x.orderId]) orders.push(x.orderId);
    });

    if (orders.length == 0) {
      toast.warning('No invoices selected.');
      return;
    }

    if (!window.confirm(`Are you sure you want to create a new invoice from the selected ones?`)) {
      return;
    }

    store.server.postApi<ServerResponse<number>>(`../Purchase/DuplicateInvoice`, { orders: orders, includeCoupons: false }).then((x) => {
      if (x.Success) {
        LoadData(months);
        setForm({ ...form, search: (form.search ?? '') + ' ' + x.Value });
        setChecked({});
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const getInvoiceStatusIconClass = (status: string) => {
    //Removed pending Partial Complete
    switch (status.toLowerCase()) {
      case 'removed':
        return <i className="far fa-minus-square text-danger" />;
      case 'pending':
        return <i className="far fa-clock text-warning" />;
      case 'complete':
        return <i className="fa fa-check-square-o text-success" />;
      case 'refunded':
        return <i className="fas fa-undo text-primary" />;
      case 'charge back':
        return <Icon type={IconType.warning} addClass='text-danger' title='Charge Back!'/>;
      default:
        return <i className="far fa-question-square text-info" />;
    }
  };

  const getPaidIcon = (paid: boolean) => {
    if (paid)
      return (
        <span className="text-success">
          <Icon type={IconType.money} />
        </span>
      );
    return (
      <span className="text-warning">
        <Icon type={IconType.warning} />
      </span>
    );
  };

  return (
    <div className="col-sm-12">
      <div className="flex-between">
        <h3>Invoice Management</h3>
        <div>

{Object.keys(checked).length > 0 && <button
            type="button"
            className="btn btn-default"
            onClick={() => {
              setChecked({});
            }}>
            <Icon type={IconType.checkSquareO} /> Deslect All
          </button>}

          <button
            type="button"
            className="btn btn-default"
            onClick={() => {
              MakeInvoiceFromSelected();
            }}>
            <Icon type={IconType.invoice} /> Invoice From Selected
          </button>

          <button
            type="button"
            className="btn btn-default"
            onClick={() => {
              LoadData(months);
            }}>
            <Icon type={IconType.refresh} /> Refresh
          </button>
        </div>
      </div>
      <hr />

      <Tabs>
        <TabList>
          <Tab>Invoices</Tab>
          <Tab>Problem Invoices</Tab>
        </TabList>

        <TabPanel>
          <div className="form-horizontal">
            <div className="form-group">
              <label className="col-sm-3 control-label" htmlFor="search-tb">
                Search
              </label>
              <div className="col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  id="search-tb"
                  autoFocus
                  value={form?.search}
                  onChange={(e) => {
                    setForm({ ...form, search: e.target.value });
                  }}
                  maxLength={250}
                />
              </div>
              <div className="col-sm-3">
                <select
                  className="form-control"
                  value={months}
                  onChange={(e) => {
                    setMonths(+e.target.value);
                    LoadData(+e.target.value);
                  }}>
                  <option value={1}>Last Month</option>
                  <option value={2}>Last 2 Months</option>
                  <option value={3}>Last 3 Months</option>
                  <option value={4}>Last 4 Months</option>
                  <option value={5}>Last 5 Months</option>
                  <option value={6}>Last 6 Months</option>
                  <option value={7}>Last 7 Months</option>
                  <option value={8}>Last 8 Months</option>
                  <option value={9}>Last 9 Months</option>
                  <option value={10}>Last 10 Months</option>
                  <option value={11}>Last 11 Months</option>
                  <option value={12}>Last 12 Months</option>
                </select>
              </div>
            </div>
          </div>
          <div className="orders-summary">
            {filteredRecords.map((x, i) => {
              return (
                <div key={`record-${i}`} className="flex-between record">
                  <div>
                    <input
                      type="checkbox"
                      className="select-order"
                      onChange={(e) => {
                        checked[x.orderId] = e.target.checked;
                        setChecked({ ...checked });
                      }}
                      checked={checked[x.orderId] ?? false}
                    />
                  </div>
                  <div className="grow flex-between">
                    <div>
                      {data?.shouldShowAffiliates && <span className="mute">{x.affiliate}</span>}
                      {getInvoiceStatusIconClass(x.orderStatus)}
                    </div>
                    <div className="grow">
                      <div>
                        <div className="flex-between">
                          <div>
                            <div>{x.accountHolder} </div>
                            <div className="mute">{x.accountHolderEmail}</div>
                          </div>
                          <div className="text-centered">
                            <div>{x.transactionNumber}</div>
                          </div>
                          <div className="text-right">
                            {!x.paid && <a href={StoreX.BuildUrlWithFair(`../app/store/invoice/${x.Id}`)} 
                            target={'_blank'} title='Edit Invoice' ><Icon type={IconType.edit} /> Edit </a>}
                            <a href={StoreX.BuildUrlWithFair(x.invoiceUri)} target={'_blank'} title="click to view invoice" className="order-id">
                              <Icon type={IconType.fileInvoice} /> {x.orderId}
                            </a>
                            <div>
                              $ {x.totalAmountPaid} on {x.orderDate}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex wrap'>
                        {x.CouponCodes && x.CouponCodes.length > 0 && (
                          <>
                            {x.CouponCodes.map((c, ci) => {
                              return (
                                <span key={`cci-${i}`} className="coupon">
                                  {c}
                                </span>
                              );
                            })}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            <h4>Problem Invoices</h4>
            The following invoice groups are a bit problemmatic you see. Each invoice group contains multiple invoices for the same participant registration fees.
          </div>
          <div className="problem-invoices-container">
            {filteredProblems &&
              filteredProblems.length > 0 &&
              filteredProblems.map((x, i) => {
                return (
                  <div className="problem-invoice-group" key={`igp-${i}`}>
                    <div className="flex-between">
                      <h4>{x.ProjectId ?? 'Unkown'}</h4>
                      <span>{x.Title ?? 'Unkown'}</span>
                    </div>
                    <hr />
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Paid</th>
                          <th>Order Id</th>
                          <th>Total</th>
                          <th>Has none registration fees</th>
                        </tr>
                      </thead>
                      <tbody>
                        {x.Orders.map((o, i2) => {
                          return (
                            <tr key={`igp-${i}-${i2}`}>
                              <td>
                                {!o.Paid && (
                                  <span
                                    className="click text-danger"
                                    onClick={() => {
                                      RemoveInvoice(o.OrderPublicId);
                                    }}>
                                    <Icon type={IconType.trashO} />
                                  </span>
                                )}
                              </td>
                              <td>
                                {getPaidIcon(o.Paid)} {o.PaidAt}
                              </td>
                              <td>
                                <a href={StoreX.BuildUrlWithFair(o.InvoiceUrl)} target={'_blank'} title="click to view invoice">
                                  {o.OrderId}
                                </a>
                              </td>
                              <td>$ {o.Total.toFixed(2)}</td>
                              <td>
                                {o.HasNonRegFees && (
                                  <>
                                    <Icon type={IconType.warning} /> Has none registration fees in order
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                );
              })}
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};
