import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { IState } from '../../redux/redux';
import { IAwardInfo, ISlideInfo, ISlideShowItem, ISlideShowResponse, ISponsorInfo, IWinnerInfo } from './SlideShowTypes';
import Button from '../_Core/Button';
import Modal from '../Modal/Modal';
import ConfigFormEditor from '../Setup/ConfigForm/ConfigFormEditor';
import StoreX from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';
import { toast } from 'react-toastify';
import Slide from './Slide';
import ConfigFormLanding from '../Setup/ConfigForm/ConfigFormLanding';
import Icon, { IconType } from '../Icon/Icon';
import { IEntryInfo } from '../_Core/CoreTypes';

interface IProps extends IState {}

const SlideShow = (props: IProps) => {
  const [ready, setReady] = useState<boolean>(false);
  const [data, setData] = useState<ISlideShowResponse>();
  const [slideShowType, setSlideShowType] = useState<string>(window.location.pathname.split('/').pop());
  const [showManageSlideShows, setShowManageSlideShows] = useState<boolean>(false);
  const [slideData, setSlideData] = useState<ISlideShowItem>();
  const [slides, setSlides] = useState<ISlideInfo[]>([]);
  const [activeSlide, setActiveSlide] = useState<number>(-1);
  const [projectDic, setProjectDic] = useState<Record<number, IEntryInfo>>({});
  const [assignedCategoryAwardsDic, setAssignedCategoryAwardsDic] = useState<Record<string, IWinnerInfo[]>>({});
  const [awardsDic, setAwardsDic] = useState<Record<number, IAwardInfo>>({});
  const activeSlideIndex = useRef<number>(-1);
  const numberOfSlides = useRef<number>(0);
  // const [activeSlide, setActiveSlide] = useState<number>(-1);

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      let index = activeSlideIndex.current;
      //console.log(index, event);

      const code = event.code;

      switch (code) {
        case 'ArrowLeft':
        case 'ArrowUp':
          activeSlideIndex.current = Math.max(0, index - 1);
          break;
        case 'ArrowRight':
        case 'ArrowDown':
          activeSlideIndex.current = Math.min(numberOfSlides.current - 1, index + 1);
          // setActiveSlide(Math.min(slides.length -1, index + 1))
          break;
        case 'Escape':
        case 'KeyX':
          activeSlideIndex.current = -1;
          break;
      }

      setActiveSlide(activeSlideIndex.current);
    };
    
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (props.User && !ready) {
      init();
    }
  }, [props.User]);

  useEffect(() => {
    buildSlides();
  }, [slideData]);

  const init = () => {
    StoreX.instance.server.postApi<ServerResponse<ISlideShowResponse>>(`../Important/data`, { type: 'SlideShow', subType: slideShowType }).then((x) => {
      if (x.Success) {
        let dic: Record<number, IEntryInfo> = {};
        x.Value.projects?.forEach((p) => {
          dic[p.id] = p;
        });
        let awardsDic: Record<number, IAwardInfo> = {};
        x.Value.categoryAwards?.forEach((a) => {
          awardsDic[a.id] = a;
        });

        let assignedDic: Record<string, IWinnerInfo[]> = {};
        x.Value.assignedCategoryAwards?.forEach((a) => {
          const p = dic[a.projectId];
          if (p) {
            const cateAwards = assignedDic[`${p.category_id}`] ?? [];
            const cateDivisionAwards = assignedDic[`${p.category_id}:${p.DivisionId}`] ?? [];
            const award = awardsDic[a.awardId];
            if (award) {
              let winner: IWinnerInfo = {
                Title: p.title,
                ProjectId: p.project_id,
                AwardSubType: award.name,
                AwardId: award.id,
                Points: award.points,
                Owners: p.ownerString,
              };
              cateAwards.push(winner);
              cateDivisionAwards.push(winner);
              assignedDic[`${p.category_id}`] = cateAwards;
              assignedDic[`${p.category_id}:${p.DivisionId}`] = cateDivisionAwards;
            }
          }
        });
        setAssignedCategoryAwardsDic(assignedDic);
        setAwardsDic(awardsDic);
        setProjectDic(dic);
        setData(x.Value);
        setSlideData(x.Value.data[0]);
        setReady(true);
      } else {
        toast.error(x.Message);
        console.error(x.Message);
      }
    });
  };

  const buildSlides = () => {
    let slides: ISlideInfo[] = [];

    let background = slideData?.PrimaryColor ?? '#222222';
    let text = slideData?.TextColor ?? '#DDDDDD';
    let primaryColor = slideData?.PrimaryColor ?? '#222222';

    slides.push({
      Title: slideData?.Title,
      SubTitle: slideData?.Description,
      BackgroundColor: background,
      PrimaryColor: primaryColor,
      TextColor: text,
    });

    let nextSlide: ISlideInfo;

    slideData?.AutoPages?.forEach((x, i) => {
      switch (x.toUpperCase()) {
        case 'CategoryAwardsByDivision'.toUpperCase():
            
                slides.push({
                    Title: 'Category Awards',
                    SubTitle: 'By Division',
                    BackgroundColor: background,
                    PrimaryColor: primaryColor,
                    TextColor: text,
                  });
        
                  data.categories.forEach((x) => {
                    data.divisions.forEach((d)=>{
                    nextSlide = { SubTitle: x.name, Description: d.name, BackgroundColor: background, PrimaryColor: primaryColor, TextColor: text };
                    const assignedAwards = assignedCategoryAwardsDic[`${x.id}:${d.id}`];
                    if (assignedAwards && assignedAwards.length > 0) {
                      slides.push(nextSlide);
                      nextSlide.Winners = assignedAwards.sort((a, b) => {
                        return a.Points < b.Points ? -1 : 1;
                      });
                    }
                    });
                  });
        
            
            break;
        case 'CategoryAwards'.toUpperCase():
          slides.push({
            Title: 'Category Awards',
            BackgroundColor: background,
            PrimaryColor: primaryColor,
            TextColor: text,
          });

          data.categories.forEach((x) => {
            nextSlide = { SubTitle: x.name, BackgroundColor: background, PrimaryColor: primaryColor, TextColor: text };
            const assignedAwards = assignedCategoryAwardsDic[`${x.id}`];
            if (assignedAwards && assignedAwards.length > 0) {
              slides.push(nextSlide);
              nextSlide.Winners = assignedAwards.sort((a, b) => {
                return a.Points < b.Points ? -1 : 1;
              });
            }
          });

          break;
        case 'SpecialAwards'.toUpperCase():
          slides.push({
            Title: 'Special Awards',
            BackgroundColor: background,
            PrimaryColor: primaryColor,
            TextColor: text,
          });

          data.specialAwards.forEach((x) => {
            let awards = data.assignedSpecialAwards?.filter((y) => y.awardId === x.id) ?? [];

            if (awards.length > 0) {
              nextSlide = { SubTitle: x.name, Description: x.description, BackgroundColor: background, PrimaryColor: primaryColor, TextColor: text };
              slides.push(nextSlide);

              let winners: IWinnerInfo[] = [];
              nextSlide.Winners = winners;

              awards.forEach((w) => {
                let project = projectDic[w.projectId];
                if (project) {
                  winners.push({
                    Title: project.title,
                    ProjectId: project.project_id,
                    Owners: project.ownerString,
                  });
                }
              });

              // Add Winners to slide
              // What to do if there are more than 7 winners??? Make extra slide
              // TODO: get owners so we can display names
            }
          });

          break;
        case 'Sponsors'.toUpperCase():
          slides.push({
            Title: 'Thanks to our Sponsors!',
            SubTitle: "We couldn't do it without their generous support, please checkout our amazing sponsors that support us.",
            BackgroundColor: background,
            PrimaryColor: primaryColor,
            TextColor: text,
          });
          if (data.sponsors && data.sponsors.length > 0) {
            let numberOfSlides = Math.ceil(data.sponsors.length / 4.0);
            let sponsorsPer = Math.floor(data.sponsors.length / numberOfSlides);
            let remainder = data.sponsors.length % numberOfSlides;
            console.log('Slides', numberOfSlides, sponsorsPer, remainder);
            let sponsors = [...data.sponsors];

            for (let si = 0; si < numberOfSlides; si++) {
              nextSlide = { SubTitle: 'Thank You!', BackgroundColor: background, PrimaryColor: primaryColor, TextColor: text };
              slides.push(nextSlide);

              let infos: ISponsorInfo[] = [];
              nextSlide.Sponsors = infos;

              for (let i = 0; i < sponsorsPer; i++) {
                infos.push(sponsors.pop());
              }
              if (remainder > 0) {
                remainder--;
                infos.push(sponsors.pop());
              }
            }
          }
          break;
        case 'ThankYouJudges'.toUpperCase():
          slides.push({
            Title: 'Thank You Judges',
            SubTitle: "Thank you for your time and effort, we couldn't do it without you!",
            BackgroundColor: background,
            PrimaryColor: primaryColor,
            TextColor: text,
          });
          break;
        case 'ThankYouVolunteer'.toUpperCase():
          slides.push({
            Title: 'Thank You Volunteers',
            SubTitle: 'Thank you for your time and effort, you make all of this possible.',
            BackgroundColor: background,
            PrimaryColor: primaryColor,
            TextColor: text,
          });
          break;
      }
    });

    setSlides(slides);
    numberOfSlides.current = slides.length;
  };

  return (
    <>
      {!ready && (
        <div>
          <h3>Loading Slide Show</h3>
        </div>
      )}
      {ready && (
        <>
          <div className="flex-between">
            <div></div>
            <div>
              <Button type={'refresh'} onClick={() => init()} text="Refresh" />
              <Button type={'edit'} onClick={() => setShowManageSlideShows(true)} text="Manage Shows" />
            </div>
          </div>
          <div>
            <h3>Slide Viewer</h3>
            <p>You can navigate your slides using the arrow keys and clicking your mouse. To exit the slide show press the "Esape" key or "X" key.</p>
            <p>Slides are designed to work in full screen mode ([f11] on windows), and have a 4x3 aspect ratio.</p>
            <button
              type="button"
              className="btn btn-success"
              onClick={() => {
                setActiveSlide(0);
                activeSlideIndex.current = 0;
              }}>
              <Icon type={IconType.start} /> Start
            </button>
          </div>
          <div className="slide-viewer">
            {slides &&
              slides.length > 0 &&
              slides.map((x, i) => {
                return (
                  <Slide
                    key={i}
                    slide={x}
                    active={activeSlide === i}
                    onDblClick={() => {
                      if (activeSlide !== i) {
                        setActiveSlide(i);
                        activeSlideIndex.current = i;
                      }
                    }}
                  />
                );
              })}
            {(!slides || slides.length === 0) && <div>No slides to show.</div>}
          </div>
        </>
      )}

      {showManageSlideShows && (
        <Modal size="xl" title="Manage Slide Shows" setModalOpen={setShowManageSlideShows}>
          <ConfigFormLanding type="SlideShows" />
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state.main };
};

//export default connect(mapStateToProps)(BasePageContent);
export default connect(mapStateToProps)(SlideShow);
