import React, { useEffect, useState } from "react";
import { IListItem } from "../_Core/CoreTypes";
import { store } from "../../redux/oldStore";
import { ServerResponse } from "../../utils/Server";
import Modal from "../Modal/Modal";
import { NewGuid } from "../../utils/Tools";
import Button from "../_Core/Button";

interface IProps {
    id?:string;
    value?:number;
    readonly?:boolean;
    onChange?:(x:number|null)=>void;
}

interface IGetSchool {
    school : IListItem;
}

interface IGetSchools {
    schools : IListItem[];
}

interface IGetStates {
    states : IListItem[];
}
interface IGetCounties {
    counties : IListItem[];
}

interface IForm {
    state?:string;
    county?:string;
    school?:number;
}

const SchoolPicker = (props:IProps)=>{
    const [id, setId] = useState<string>(props.id ?? NewGuid());
    const [schoolId, setSchoolId] = useState<number>(props.value);
    const [school, setSchool] = useState<IListItem>();
    const [states, setStates] = useState<IListItem[]>();
    const [counties, setCounties] = useState<IListItem[]>();
    const [countiesDic, setCountiesDic] = useState<Record<number, IListItem[]>>({});
    const [schools, setSchools] = useState<IListItem[]>();
    const [showSchoolPicker, setShowSchoolPicker] = useState<boolean>(false);
    const [form, setForm] = useState<IForm>({});

    useEffect(()=>{
        if(props.value){
            getSchool();
        }
    },[]);
    
    useEffect(()=>{
        if(showSchoolPicker && !states){
            getStates();
        }
    },[showSchoolPicker]);

    const getSchool = ()=> {
        if(schoolId > 0){
            store.server.postApi<ServerResponse<IGetSchool>>(`../info/school`, {schoolId:schoolId})
            .then(x=>{
                if(x.Success){
                    setSchool(x.Value.school);
                } else {
                    console.error(x.Message);
                }
            });
        }
    };
    const getStates = ()=> {
            store.server.getApi<ServerResponse<IGetStates>>(`../info/states`)
            .then(x=>{
                if(x.Success){
                    setStates(x.Value.states);
                } else {
                    console.error(x.Message);
                }
            });
    };
    const getCounties = (state:string)=> {
            store.server.postApi<ServerResponse<IGetCounties>>(`../info/Counties`, {state:state})
            .then(x=>{
                if(x.Success){
                    let r = countiesDic;
                    r[state] = x.Value.counties;
                    setCounties(x.Value.counties);
                    setCountiesDic(r);
                } else {
                    console.error(x.Message);
                }
            });
    };
    const getSchools = (state:string, county:string)=> {
            store.server.postApi<ServerResponse<IGetSchools>>(`../info/Schools`, {state:state, county:county})
            .then(x=>{
                if(x.Success){
                    let r = x.Value.schools;
                    setSchools(r);
                } else {
                    console.error(x.Message);
                }
            });
    };


    return <>
        <div className="form-control">
            <div className="flex-between">
            {school && <span><strong>{school.name}</strong></span>}
            {(props.readonly ? false : true) && <Button type={'edit'} iconOnly={true} text={school ? "": "Select School"} onClick={()=>{setShowSchoolPicker(true);}}/>}
            </div>
        </div>

        {showSchoolPicker && states && <Modal setModalOpen={setShowSchoolPicker} title="School Finder" size="m">
                <div className="form-horizontal">
                    <div className="form-group">
                        <label className="col-sm-4 control-label" htmlFor={`${id}-state`}>State</label>
                        <div className="col-sm-8">
                            <select
                                id={`${id}-state`}
                                className="form-control"
                                value={form.state}
                                onChange={(x)=>{
                                    setForm({...form, state:x.target.value});
                                    getCounties(x.target.value);
                                }}
                            >
                                <option></option>
                                {states.map((x,i)=>{
                                    return <option key={i} value={x.id}>{x.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    {counties && form.state && <div className="form-group">
                        <label className="col-sm-4 control-label" htmlFor={`${id}-counties`}>County</label>
                        <div className="col-sm-8">
                            <select
                                id={`${id}-counties`}
                                className="form-control"
                                value={form.county}
                                onChange={(x)=>{
                                    setForm({...form, county:x.target.value});
                                    getSchools(form.state, x.target.value);
                                }}
                            >
                                <option></option>
                                {counties.map((x,i)=>{
                                    return <option key={i} value={x.id}>{x.name}</option>
                                })}
                            </select>
                        </div>
                    </div>}
                    {schools && form.state && form.county && counties && <div className="form-group">
                        <label className="col-sm-4 control-label" htmlFor={`${id}-schools`}>School</label>
                        <div className="col-sm-8">
                            <select
                                id={`${id}-schools`}
                                className="form-control"
                                value={form.school}
                                onChange={(x)=>{
                                    setForm({...form, school:+x.target.value});
                                }}
                            >
                                <option></option>
                                {schools.map((x,i)=>{
                                    return <option key={i} value={x.id}>{x.name}</option>
                                })}
                            </select>
                        </div>
                    </div>}
                    <div className="form-group">
                        <div className="col-sm-offset-4 col-sm-8">
                            <div className="btn-group">
                            <Button type={'save'} text="Select School" disabled={form.school ? false:true}  
                                onClick={()=>{
                                    if(form.school){                                        
                                        setSchoolId(form.school);
                                        let school = schools.find(x=>x.id === form.school+'');
                                        setSchool(school);
                                        if(props.onChange){
                                            props.onChange(form.school);
                                        }
                                        setShowSchoolPicker(false);

                                    }
                                }} 
                            />
                            <Button type={'close'} text="Close"   
                                onClick={()=>{
                                        setShowSchoolPicker(false);                                    
                                }} 
                            />
                                </div>
                        </div>
                    </div>
                </div>
            </Modal>}
    </>
};


export default SchoolPicker;