import React, {useState, useEffect} from 'react'

interface IModalProps {
  setModalOpen:Function,
  className?:string,
  title:string,
  children?:React.ReactNode,
  size?:'m'|'l'|'xl'|'xxl'|'full',
  noClose?:boolean,
  noCloseOnBackgroundClick?:boolean,
}

const Modal = (props:IModalProps) => {
  const [sizeClassName, setSizeClassName] = useState<string>('');

  useEffect(()=>{
    //set body as no scroll

    document.querySelector('body')?.classList.add('modal-open');
    return ()=>{
      //remove class from body
      window.setTimeout(()=>{
        let nodes = document.querySelectorAll('.modal-not-bs');
        if(nodes.length === 0){
          document.querySelector('body')?.classList.remove('modal-open');
        }
      },100);
    };
  },[]);

  useEffect(()=>{
    let value = '';
    switch(props.size){
      
      case 'm':
        value = `modal-m`;
        break;
      case 'l':
        value = `modal-l`;
        break;
        case 'xl':
        value = `modal-xl`;
        break;
        case 'xxl':
        value = `modal-xxl`;
        break;
        
        case 'full':
        value = `modal-full`;
        break;
    }
    setSizeClassName(value);
  }, [props.size]);

  const handleModalClick = (e) => {

    if(!props.noCloseOnBackgroundClick && e.target.id === 'modal' && props.setModalOpen) {
      e.stopPropagation();

      if(props.noClose) return;

      props.setModalOpen(false)
    }
  }

  return(

    <div
      className="modal-not-bs"
      id="modal"
      onMouseDown={e=>handleModalClick(e)}
    >
      <div className={`modal-content round-border ${sizeClassName} ${props.className}`}>
        <div className="w-100">

        <div className="flex-between middle">
          <h3 dangerouslySetInnerHTML={{__html:(props.title || 'Title')}}></h3>
          {!props.noClose && <span className="click close-button text-danger" onClick={()=>{props.setModalOpen(false)}}><i className="far fa-times-circle larger"></i></span>}
        </div>
        <div className="headroom-l modal-body">
          {props.children || 'add children'}
        </div>
        </div>
      </div>
    </div>
  )
}



export default Modal