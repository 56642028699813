import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import Icon, { IconType } from "../Icon/Icon";

interface IStripePaymentProps {
    handlePayNow:(strip:Stripe, elements:StripeElements, form:istripForm)=>void;
    Amount:number;
    includeFees?:boolean;
    feePercent?:number;
    feeAmount?:number;
}

export   interface istripForm{
    invoice?:string;
    paidOn?:string;
    notes?:string;
    name?:string;
    firstName?:string;
    lastName?:string;
    donation?:string;
    email?:string;
    phone?:string;
    orderId?: string;
  }


const StripPayment = (props:IStripePaymentProps)=>{    
    const [form, setForm] = useState<istripForm>({});
    const [fee, setFee] = useState<number>(props.Amount * (props.feePercent ?? 0) + (props.feeAmount ?? 0));
    const [total, setTotal] = useState<number>(props.Amount);
    const stripe = useStripe();
    const elements = useElements();

    useEffect(()=>{
      let f = props.Amount * (props.feePercent ?? 0) + (props.feeAmount ?? 0);
      setFee(f);      
      setTotal(props.includeFees ? props.Amount + f : props.Amount);
    },[props.feeAmount, props.feeAmount, props.Amount, props.includeFees]);

    const handlePayNow = ()=>{
        if(stripe && elements) props.handlePayNow(stripe, elements, form);
    };

    return <>
                <section className="purchase-payment-section">
      <div className="form-horizontal">
        <div className="form-group">
          <label className="control-label col-sm-4" htmlFor="Amount">
            Amount
          </label>
          <div className="col-sm-4">
            <input
              id="Amount"
              className="form-control"
              maxLength={20}
              readOnly={true}
              value={`$ ${props.Amount.toFixed(2)}`}
            ></input>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-4" htmlFor="FirstName">
            Name
          </label>
          <div className="col-sm-4">
            <input
              id="FirstName"
              className="form-control"
              maxLength={20}
              autoFocus={true}
              placeholder="First Name"
              value={form.firstName}
              onChange={(e) => {
                setForm({...form,firstName:e.target.value});
              }}
            ></input>
          </div>
          <div className="col-sm-4">
            <input
              id="LastName"
              className="form-control"
              maxLength={20}
              placeholder="Last Name"
              value={form.lastName}
              onChange={(e) => {
                setForm({...form,lastName:e.target.value});
              }}
            ></input>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-4" htmlFor="Email">
            Email
          </label>
          <div className="col-sm-5">
            <input
              id="Email"
              type="email"
              className="form-control"
              maxLength={150}
              placeholder="Card Holder's Email"
              value={form.email}
              onChange={(e) => {
                setForm({...form,email:e.target.value});
              }}
            ></input>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-4" htmlFor="Phone">
            Phone
          </label>
          <div className="col-sm-4">
            <input
              id="Phone"
              type="tel"
              className="form-control"
              maxLength={20}
              placeholder="Card Holder's Phone"
              value={form.phone}
              onChange={(e) => {
                setForm({...form,phone:e.target.value});
              }}
            ></input>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-4" htmlFor="Total">
            Processing Fee
          </label>
          <div className="col-sm-4">
            <input
              id="Total"
              className="form-control"
              maxLength={20}
              readOnly={true}
              value={`$ ${fee.toFixed(2)}`}
            ></input>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-4" htmlFor="Total">
            Total
          </label>
          <div className="col-sm-4">
            <input
              id="Total"
              className="form-control"
              maxLength={20}
              readOnly={true}
              value={`$ ${total.toFixed(2)}`}
            ></input>
          </div>
        </div>

        <div className="form-group">
          <label className="control-label col-sm-4" htmlFor="Stripe">
            Credit / Debit Card
          </label>
          <div className="col-sm-8">
            <div className="form-control">
              <CardElement
                options={{
                  style: stripeCardStyle,
                }}
                className="card"
              />
              
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-8 col-sm-offset-4">
            <button
              className="btn btn-secondary"
              onClick={handlePayNow}
            >
              <Icon type={IconType.payNow} /> Pay now
            </button>
          </div>
        </div>
      </div>
    </section>
    </>
}; 

export default StripPayment



const stripeCardStyle = {
    base: {
      color: "#32325d",
      lineHeight: "18px",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  };