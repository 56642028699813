import { Routes, Route } from 'react-router-dom';
import Showcase from './Components/Showcase/Showcase'
import ParticipantPaperworkReivew from './Components/Paperwork/ParticipantPaperworkReview'
import JudgingSetupContainer from './Components/JudgingSetup/JudgingSetupContainer';
import DisplayAndSafety from './Components/DisplayAndSafety/DisplayAndSafety';
import Biliteracy from './Components/Biliteracy/Biliteracy';
import Setup from './Components/Biliteracy/Setup';
import Load from './Components/Biliteracy/Load';
import ParticipantFileManager from './Components/ParticipantFileManagement/ParticipantFileManager';
import ExternalJudgesContainer from './Components/ExternalJudges/ExternalJudgesContainer'
import ParticipantVideoContainer from './Components/ParticipantVideo/ParticipantVideoContainer'
import PdfConverter from './Components/PdfConversion/PdfConverter';
import { toast } from 'react-toastify';
import StudentReviewPortal from './Components/OnlineReview/StudentReviewPortal';
import AdultReviewPortal from './Components/OnlineReview/AdultReviewPortal';
import SuperQuizContainer from './Components/SuperQuiz/SuperQuizContainer';
import JudgingResultsContainer from './Components/JudgingResults/JudgingResultsContainer';
import EmailToolContainer from './Components/EmailTool/EmailToolContainer';
import NotificationHubContainer from './Components/NotificationHub/NotificationHubContainer';
import JustVideoContainer from './Components/JustVideo/JustVideoContainer';
import FairPromotionLinkingContainer from './Components/FairPromotionLinking/FairPromotionLinkingContainer';
import Dashboard from './Components/JudgingDashboard/Dashboard';
import TagsContainer from './Components/Tags/TagsContainer';
import Import from './Components/Import/Import';
import CirclesScheduler from './Components/Circles/CirclesScheduler';
import MyAwards from './Components/My/Awards';
import AdvancedJudgingLanding from './Components/AdvancedJudging/AdvancedJudgingLanding';
import ChatPage from './Components/SupportChat/ChatPage';
import QuestionCreator from './Components/QuestionCreation/QuestionCreator';
import RubricSetup from './Components/JudgingSetup/RubricSetup/RubricSetup';
import FormFromApiData from './Components/FormFromApiData/FormFromApiData';
import UserProjectDisplay from './Components/UserProject/UserProjectDisplay';
import PeoplePage from './Components/PeoplePage/PeoplePage';
import ChangePasswordForm from './Components/PeoplePage/ChangePasswordForm';
import ParticipantRequirements from './Components/UserRequirements/ParticipantRequirements';
import StoreFront from './Components/Store/StoreFront';
import UserRegistrationComplete from './Components/User/UserRegistrationComplete';
import ConfirmEmail from './Components/FormFromApiData/ConfirmEmail';
import FirstTimeLoginComplete from './Components/User/FirstTimeLoginComplete';
import GenericFormsLanding from './Components/GenericForms/GenericFormsLanding';
import GroupManager from './Components/GenericForms/GroupManager';
import FloorPlanner from './Components/FloorPlanner/FloorPlanner';
import SetupAll from './Components/Setup/SetupAll';
import LicenseExpired from './Components/Error/LicenseExpired';
import SetupStart from './Components/Setup/SetupStart';
import Credits from './Components/Credits';
import { InvoiceMangement } from './Components/Store/InvoiceManagement';
import ProjectDetailsWraper from './Components/Showcase/projectDetailsWraper';
import CategoryCaptainManager from './Components/SetupTools/CategoryCaptain/CategoryCaptainManager';
import EventSessionManager from './Components/SetupTools/EventSessions/EventSessionManager';
import WaitingRoom from './Components/AdvancedJudging/WaitingRoom';
import BranchContainer from './Components/Setup/Branches/BranchContainer';
import Checkin from './Components/Checkin/Checkin';
import ConfigFormLanding from './Components/Setup/ConfigForm/ConfigFormLanding';
import BasePageContent from './Components/Page/BasePageContent';
import { HtmlEditorPro2 } from './Components/Tools/htmlEditor2';
import Invoices from './Components/Organization/Invoices';
import Invoice from './Components/Organization/Invoice';
import OldSettingsLayout from './Components/Setup/OldSettingsLayout/OldSettingsLayout';
import { AssignSpecialAwards } from './Components/Management/Awards/AssignSpecialAwards';
import { ReportViewer } from './Components/Reports/Report';
import OutsideBranch from './Components/Setup/Branches/OutsideBranch';
import AssignJudgesToCategoryWizard from './Components/SetupTools/CategoryCaptain/AssignJudgesToCategoryWizard';
import Medical from './Pages/Users/Medical';
import NhdScheduleAndJudging from './Pages/Client/NHD/NhdScheduleAndJudging';
import NhdSchedule from './Pages/Client/NHD/NhdSchedule';
import JudgingCirclesMain from './Pages/Judging/JudgingCirclesMain';
import FlexFlowManagerPage from './Pages/Flex/Setup/FlexFlowManagerPage';
import FlexFlowPage from './Pages/Flex/FlexFlowPage';
import FlexFlowDonePage from './Pages/Flex/FlexFlowDonePage';
import JudgingFeedback from './Components/JudgingResults/JudgingFeedback';
import FlexFlowFeePage from './Pages/Flex/FlexFlowFeePage';
import FlexViewer from './Components/FlexFlow/FlexViewer';
import ExternalLanding from './Pages/External/ExternalLanding';
import SchoolAffiliateManager from './Components/School/SchoolAffiliateManager';
import SchoolAffilateForm from './Components/School/SchoolAffilateForm';
import NewUserLanding from './Components/User/NewUserLanding';
import ConfigGroupViewer from './Components/Setup/ConfigGroupViewer';
import StartedRegistrations from './Components/User/StartedRegistrations';
import SlideShow from './Components/SlideShow/SlideShow';
import TestGround from './Components/Tools/TestGround';

const uploadFileHook = (pdfUrl: string) => {
  toast.info(pdfUrl);
}

export default (
  <Routes>
    <Route path="/app/reivewportal/student" Component={StudentReviewPortal} />
    <Route path="/app/reivewportal/adult" Component={AdultReviewPortal} />

    <Route path='/app/showcase/entry/:projectKey' Component={ProjectDetailsWraper} />
    <Route path='/app/showcase/:projectKey' Component={ProjectDetailsWraper} />
    <Route path="/app/showcase" Component={Showcase} />
    <Route path='/app/paperwork/participant' Component={ParticipantPaperworkReivew} />

    <Route path='/app/judging/setup/rubric' Component={RubricSetup} />
    <Route path='/app/judging/setup' Component={JudgingSetupContainer} />
    <Route path='/app/app/judging/setup' Component={JudgingSetupContainer} />
    <Route path='/app/judging/results' Component={JudgingResultsContainer} />
    <Route path='/app/judging/dashboard' Component={Dashboard} />

    <Route path='/app/displayandsafety' Component={DisplayAndSafety} />

    <Route path='/app/Biliteracy/Setup' Component={Setup} />
    <Route path='/app/Biliteracy/Load' Component={Load} />
    <Route path='/app/Biliteracy' Component={Biliteracy} />

    <Route path='/app/externaljudges' Component={ExternalJudgesContainer} />

    <Route path='/app/video/participant' Component={ParticipantVideoContainer} />
    <Route path='/app/video/:roomId' Component={JustVideoContainer} />
    <Route path='/app/CirclesScheduler/:roundId' Component={CirclesScheduler} />

    <Route path='/app/AdvancedJudging/:userId' Component={AdvancedJudgingLanding} />

    <Route path='/app/SupportChat' Component={ChatPage} />
    <Route path='/app/My/Awards' Component={MyAwards} />

    <Route path='/app/superquiz' Component={SuperQuizContainer} />
    <Route path='/app/emailtool' Component={EmailToolContainer} />
    <Route path='/app/notifications' Component={NotificationHubContainer} />
    <Route path='/app/fair/promotionlinking' Component={FairPromotionLinkingContainer} />
    <Route path='/app/tags' Component={TagsContainer} />
    <Route path='/app/import' Component={Import} />
    <Route path='/app/files' Component={() => (<ParticipantFileManager isNew={false} />)} /> {/*Render accepts a funcional component and that function won't get unnecessarily remonuted like with Component=*/}
    <Route path='/app/pdf-convert' Component={() => (<PdfConverter uploadFileHook={uploadFileHook} />)} />
    <Route path='/app/question-creator' Component={QuestionCreator}></Route>

    <Route path='/app/WaitingRoom/:userId' Component={()=> <WaitingRoom />} />

   <Route
      path='/app/user/profile/:targetId/:roleName'
      Component={() =>
        <FormFromApiData
          idCalledInApi='userId'
          getInfoRoute='../UserProfile/MainForm'
          saveRoute='../UserProfile/SaveForm'
          isNew={false}
        />
      }
    /> 

<Route path='/app/school/affiliate/record/:affiliateId' Component={SchoolAffilateForm}/>
<Route path='/app/school/affiliate/record' Component={SchoolAffilateForm}/>

<Route path='/app/school/affiliate/:schoolId' Component={SchoolAffiliateManager}/>

<Route path='/app/SlideShow/:type' Component={SlideShow}/>

    <Route path='/app/JudgingCircles' Component={()=><JudgingCirclesMain />}/>
    <Route path='/app/JudgingFeedback' Component={()=><JudgingFeedback />}/>


    <Route path='/app/client/nhd/scheduleAndJudging' Component={()=><NhdScheduleAndJudging />}/>
    <Route path='/app/client/nhd/schedule' Component={()=><NhdSchedule />}/>
    <Route path='/app/client/nhd/scheduling' Component={()=><NhdSchedule />}/>

    <Route path='/app/user/started' Component={()=><StartedRegistrations />}/>
    <Route path='/app/user/email/:targetId/' Component={()=><ConfirmEmail />}/>
    <Route path='/app/user/profile/:targetId/' Component={() => <FormFromApiData isNew={false} idCalledInApi='userId' getInfoRoute='../UserProfile/MainForm' saveRoute='../UserProfile/SaveForm'/>} />
  
    <Route path='/app/user/new/:roleName/:targetId/' Component={() => <FormFromApiData isNew={true} idCalledInApi='userId' getInfoRoute='../UserProfile/MainForm' saveRoute='../UserProfile/SaveForm'/>} />
    <Route path='/app/user/new/:roleName/' Component={() => <FormFromApiData isNew={true} idCalledInApi='userId' getInfoRoute='../UserProfile/MainForm' saveRoute='../UserProfile/SaveForm'/>} />
    <Route path='/app/user/new' Component={NewUserLanding}/>
    
    <Route path='/app/user/firstTimeLogin/Done/:targetId/' Component={() => <FirstTimeLoginComplete Type={'FirstTime'} />}/>
    <Route path='/app/user/Update/Done/:targetId/' Component={() => <FirstTimeLoginComplete Type={'ProfileUpdate'} />}/>
    <Route path='/app/user/firstTimeLogin/:targetId' Component={() => <FormFromApiData isNew={false} idCalledInApi='userId' getInfoRoute='../UserProfile/MainForm' saveRoute='../UserProfile/SaveForm'/>} />

    <Route path='/app/user/password' Component={() => <ChangePasswordForm isSelfPasswordChange={true} />} />
    <Route path='/app/user/profile/' Component={() => <FormFromApiData isNew={false} idCalledInApi='userId' getInfoRoute='../UserProfile/MainForm' saveRoute='../UserProfile/SaveForm'/>}/>
    
    <Route path='/app/project/:userId/:targetId/:entryType' Component={()=> <UserProjectDisplay AlwaysShow={false} UserId={undefined}/>} />
    <Route path='/app/project/:userId/:targetId' Component={()=> <UserProjectDisplay AlwaysShow={false} UserId={undefined}/>} />
    <Route path='/app/project/:userId' Component={()=> <UserProjectDisplay AlwaysShow={false}  UserId={undefined}/>} />
    <Route path='/app/projects/:userId' Component={()=> <UserProjectDisplay AlwaysShow={true}  UserId={undefined}/>} />
    <Route path='/app/projects/:userId' Component={()=><UserProjectDisplay AlwaysShow={true}  UserId={undefined}/>}/>
    

    <Route path='/app/people' Component={PeoplePage} />
    <Route path='/app/user/requirements/:userId' Component={ParticipantRequirements} />
    <Route path='/app/user/medical/:userId' Component={Medical} />
    <Route path='/app/user/done/:userId/:projectId' Component={UserRegistrationComplete} />
    <Route path='/app/user/done/:userId' Component={UserRegistrationComplete} />


    <Route path ='/app/store/invoicemanagement' Component={InvoiceMangement} />
    <Route path ='/app/store/invoice/:invoice' Component={StoreFront} />
    <Route path ='/app/store/:userId' Component={StoreFront} />
    <Route path ='/app/store' Component={StoreFront} />

    <Route path='/app/form/manager/' Component={()=> <GroupManager />} />
    <Route path='/app/form/group/:groupId' Component={()=> <GenericFormsLanding />} />
    
    <Route path='/app/form/sign/:groupId/:projectId/:signerId' Component={()=> <GenericFormsLanding isSign={true} />} />

    <Route path='/app/floorplanner' Component={FloorPlanner} />


    <Route path='/app/organization/invoices' Component={()=><Invoices />}/>
    <Route path='/app/organization/invoice/:invoiceId' Component={()=><Invoice />}/>
    
    <Route path='/app/setup/group/:groupKey' Component={ConfigGroupViewer} />
    <Route path='/app/setup/old' Component={OldSettingsLayout} />
    



    <Route path='/app/flex/Viewer' Component={()=><FlexViewer />} />
    <Route path='/app/flex/done/:flowId/:recordId' Component={()=><FlexFlowDonePage />} />
    <Route path='/app/flex/fee/:flowId/:recordId' Component={()=><FlexFlowFeePage />} />
    <Route path='/app/flex/:flowId/:recordId' Component={()=><FlexFlowPage />} />
    <Route path='/app/flex/:flowId' Component={()=><FlexFlowPage />} />
    <Route path='/app/setup/flex/:flowId' Component={()=><FlexFlowManagerPage />} />

    
            
    <Route path='/app/setup/xxx' Component={()=><ConfigFormLanding type='xxx' />} />
    <Route path='/app/setup/photo-booth-obj' Component={()=><ConfigFormLanding type='photo-booth-obj' />} />    
    <Route path='/app/setup/participants-removed' Component={()=><ConfigFormLanding type='participants-removed' />} />
    <Route path='/app/setup/affiliate' Component={()=><ConfigFormLanding type='affiliate' />} />
    <Route path='/app/setup/fair-results' Component={()=><ConfigFormLanding type='fair-results' />} />
    <Route path='/app/setup/flex-flow-instructions' Component={()=><ConfigFormLanding type='flex-flow-instructions' />} />
    <Route path='/app/setup/flex-flow' Component={()=><ConfigFormLanding type='flex-flow' />} />
    <Route path='/app/setup/flex-page' Component={()=><ConfigFormLanding type='flex-page' />} />
    <Route path='/app/setup/flex-section' Component={()=><ConfigFormLanding type='flex-section' />} />
    <Route path='/app/setup/flex-content' Component={()=><ConfigFormLanding type='flex-content' />} />
    <Route path='/app/setup/invite' Component={()=><ConfigFormLanding type='invite' />} />
    <Route path='/app/setup/text' Component={()=><ConfigFormLanding type='text' />} />
    <Route path='/app/setup/pages' Component={()=><ConfigFormLanding type='pages' />} />
    <Route path='/app/setup/page' Component={()=><ConfigFormLanding type='pages' />} />
    <Route path='/app/setup/event-sessions' Component={()=><ConfigFormLanding type='event-sessions' />} />
    <Route path='/app/setup/entry-types' Component={()=><ConfigFormLanding type='entry-types' />} />
    <Route path='/app/setup/judging-rubric' Component={()=><ConfigFormLanding type='judging-rubric' />} />
    <Route path='/app/setup/judging-rounds' Component={()=><ConfigFormLanding type='judging-rounds' />} />
    <Route path='/app/setup/judging' Component={()=><ConfigFormLanding type='judging' />} />
    <Route path='/app/setup/client-files' Component={()=><ConfigFormLanding type='client-files' />} />
    <Route path='/app/setup/schools' Component={()=><ConfigFormLanding type='schools' />} />
    <Route path='/app/setup/category-chairs' Component={()=><ConfigFormLanding type='category-chairs' />} />
    <Route path='/app/setup/src-irb' Component={()=><ConfigFormLanding type='src-irb' />} />
    <Route path='/app/setup/paperwork' Component={()=><ConfigFormLanding type='paperwork' />} />
    <Route path='/app/setup/tags' Component={()=><ConfigFormLanding type='tags' />} />
    <Route path='/app/setup/sponsors' Component={()=><ConfigFormLanding type='sponsors' />} />
    <Route path='/app/setup/school-districts' Component={()=><ConfigFormLanding type='school-districts' />} />
    <Route path='/app/setup/ecommerce' Component={()=><ConfigFormLanding type='ecommerce' />} />
    <Route path='/app/setup/coupons' Component={()=><ConfigFormLanding type='coupons' />} />
    <Route path='/app/setup/shirt-sizes' Component={()=><ConfigFormLanding type='shirt-sizes' />} />
    <Route path='/app/setup/awards-special-donors' Component={()=><ConfigFormLanding type='awards-special-donors' />} />
    <Route path='/app/setup/awards' Component={()=><ConfigFormLanding type='awards' />} />
    <Route path='/app/setup/awards-special' Component={()=><ConfigFormLanding type='awards-special' />} />
    <Route path='/app/setup/divisions' Component={()=><ConfigFormLanding type='divisions' />} />
    <Route path='/app/setup/session-judge' Component={()=><ConfigFormLanding type='session-judge' />} />
    <Route path='/app/setup/session-volunteer' Component={()=><ConfigFormLanding type='session-volunteer' />} />
    <Route path='/app/setup/custom-questions' Component={()=><ConfigFormLanding type='custom-questions' />} />
    <Route path='/app/setup/custom-questions-participant' Component={()=><ConfigFormLanding type='custom-questions-participant' />} />
    <Route path='/app/setup/custom-questions-entry' Component={()=><ConfigFormLanding type='custom-questions-entry' />} />
    <Route path='/app/setup/custom-questions-project' Component={()=><ConfigFormLanding type='custom-questions-entry' />} />
    <Route path='/app/setup/custom-questions-teacher' Component={()=><ConfigFormLanding type='custom-questions-teacher' />} />
    <Route path='/app/setup/custom-questions-judge' Component={()=><ConfigFormLanding type='custom-questions-judge' />} />
    <Route path='/app/setup/custom-questions-volunteer' Component={()=><ConfigFormLanding type='custom-questions-volunteer' />} />
    <Route path='/app/setup/custom-questions-attendee' Component={()=><ConfigFormLanding type='custom-questions-attendee' />} />
    <Route path='/app/setup/requirements' Component={()=><ConfigFormLanding type='requirements' />} />
    <Route path='/app/setup/categories' Component={()=><ConfigFormLanding type='category' />} />
    <Route path='/app/setup/sub-categories' Component={()=><ConfigFormLanding type='sub-category' />} />
    <Route path='/app/setup/sub-sub-categories' Component={()=><ConfigFormLanding type='sub-sub-category' />} />
    <Route path='/app/setup/category' Component={()=><ConfigFormLanding type='category' />} />
    <Route path='/app/setup/sub-category' Component={()=><ConfigFormLanding type='sub-category' />} />
    <Route path='/app/setup/sub-sub-category' Component={()=><ConfigFormLanding type='sub-sub-category' />} />
    



      
    <Route path='/app/setup/branch/test' Component={()=><BranchContainer />} />
    <Route path='/app/setup/start' Component={()=><SetupStart />}/>
    <Route path='/app/setup/HTML' Component={()=><HtmlEditorPro2 value={''} changed={()=>{}}  />}/>

<Route path="/app/setup">
      <Route path=':configKey' Component={ConfigFormLanding} />
</Route>

    {/* <Route path='/app/setup/:configKey' Component={ConfigFormLanding} /> */}

    <Route path='/app/setup/' Component={SetupAll}/>


    <Route path='/app/admin/setup/eventSession' Component={()=><EventSessionManager />}/>
    <Route path='/app/admin/setup/categorychair' Component={()=><CategoryCaptainManager />}/>
    <Route path='/app/admin/setup/JudgesAssignCategory' Component={()=><AssignJudgesToCategoryWizard />}/>
    <Route path='/app/admin/checkin' Component={()=><Checkin />} />
    <Route path='/app/admin/assign-special-awards' Component={()=><AssignSpecialAwards />} />


    <Route path='/app/reports/JudgingAssignmentsReportStickers' Component={()=><ReportViewer type="JudgingAssignmentsReportStickers" />}/>
    
    <Route path='/app/error/license' Component={()=><LicenseExpired />}/>
    <Route path='/app/credits' Component={()=>Credits}/>

    
    <Route path="/app/outside" Component={()=><OutsideBranch />} />


    <Route path="/app/external/landing/:type" Component={()=><ExternalLanding />}/>
    <Route path="/app/external/landing" Component={()=><ExternalLanding />}/>
    
    <Route path="/app/page/:pageId" Component={BasePageContent} />
    <Route path="/page/:pageId" Component={BasePageContent} />
    <Route path="/app/page" Component={()=><BasePageContent pageId={'home'} />} />    
    <Route path="/app/home" Component={()=><BasePageContent pageId='home' />} />
    <Route path="/app" Component={()=><BasePageContent pageId='home' />} />
    <Route path='/' Component={()=><BasePageContent pageId='home'  />} />


    <Route path="/test" Component={TestGround}/>
  </Routes>
)