import { EasyImage } from '../Tools/ExpandableDiv';
import { ISlideInfo } from './SlideShowTypes';

interface IProps {
  slide: ISlideInfo;
  active: boolean;
  onDblClick?:()=>void;
}

const Slide = (props: IProps) => {
  return (
    <div className={`slide-wrapper ${props.active ? 'active-slide' : ''}`} onDoubleClick={()=>{
        if(props.onDblClick) props.onDblClick();
    }}>
      <div className="slide" style={{ backgroundColor: props.slide.BackgroundColor, color: props.slide.TextColor }}>
        {props.slide.Title && <h1>{props.slide.Title}</h1>}
        {props.slide.SubTitle && <h2>{props.slide.SubTitle}</h2>}
        {props.slide.Description && <p>{props.slide.Description}</p>}
        {props.slide.Sponsors && props.slide.Sponsors.length > 0 && (
          <div className="slide-sponsor-list">
            {props.slide.Sponsors.map((x, i) => {
              return (
                <div key={i} className="sponsor">
                  {x.logoAddress ? <EasyImage key={i} src={x.logoAddress} showCloudsOnError={true} /> : <h2>{x.name}</h2>}
                </div>
              );
            })}
          </div>
        )}
        {props.slide.Winners && props.slide.Winners.length > 0 && (
          <div className="slide-winner-list">
            {props.slide.Winners.map((x, i) => {
              return (
                <p>
                  {x.AwardSubType && <h2>{x.AwardSubType}</h2>}
                  <strong>{x.ProjectId}</strong> - <i>{x.Title}</i>
                  {x.Owners && <><div>{x.Owners}</div></>}
                  {/** List Owners */}
                </p>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Slide;
